// import React from 'react';
// import Plot from 'react-plotly.js';

// const TreemapChart = ({ data }) => {
//   const treemapData = Object.keys(data).map((key) => ({
//     type: 'treemap',
//     labels: data[key].labels,
//     parents: data[key].parents,
//     values: data[key].values,
//     marker: {
//       colors: [
//         'rgba(200, 200, 200, 0.5)', // Root color
//         'rgba(165, 132, 224, 0.7)', // Purple
//         'rgba(165, 132, 224, 1)', // Darker Purple
//         'rgba(255, 102, 0, 1)', // Orange
//         'rgba(255, 153, 102, 1)', // Light Orange
//         'rgba(255, 102, 0, 0.5)', // Reddish-Orange
//         'rgba(255, 255, 153, 1)', // Yellow
//         'rgba(255, 178, 102, 1)', // Soft Yellow
//       ],
//       line: { width: 2, color: 'black' },
//     },
//     textinfo: 'label+value',
//     textposition: 'middle center',
//     textfont: {
//       color: 'black' // Set a uniform text color for better readability
//     },
//     insidetextfont: {
//       color: 'black' // Ensures that inside text is legible
//     },
//     outsidetextfont: {
//       color: 'black' // Ensures outside text is legible
//     },
//     autocolorscale: false,
//   }));

//   return (
//     <div style={{ width: '250px', height: '100px' }}>
//       <Plot
//         data={treemapData}
//         layout={{
//           margin: { t: 0, l: 0, r: 0, b: 0 },
//           width: 255,
//           height: 273,
//           paper_bgcolor: 'rgba(0,0,0,0)',
//           plot_bgcolor: 'rgba(0,0,0,0)',
//           xaxis: { showgrid: false, zeroline: false, visible: false }, // Remove x-axis lines
//   yaxis: { showgrid: false, zeroline: false, visible: false }, // Remove y-axis lines
//         }}
//         config={{
//           displayModeBar: false,
//         }}
//       />
//     </div>
//   );
// };

// export default TreemapChart;



import React from 'react';
import Plot from 'react-plotly.js';

const TreemapChart = ({ data }) => {
  const treemapData = Object.keys(data).map((key) => ({
    type: 'treemap',
    labels: data[key].labels,
    parents: data[key].parents,
    values: data[key].values,
    marker: {
      colors: [
        'rgba(200, 200, 200, 0.5)', // Root color
        'rgba(165, 132, 224, 0.7)', // Purple
        'rgba(165, 132, 224, 1)', // Darker Purple
        'rgba(255, 102, 0, 1)', // Orange
        'rgba(255, 153, 102, 1)', // Light Orange
        'rgba(255, 102, 0, 0.5)', // Reddish-Orange
        'rgba(255, 255, 153, 1)', // Yellow
        'rgba(255, 178, 102, 1)', // Soft Yellow
      ],
      line: { width: 2, color: 'black' },
    },
    textinfo: 'label+value',
    textposition: 'middle center',
    textfont: {
      color: 'black' // Set a uniform text color for better readability
    },
    insidetextfont: {
      color: 'black' // Ensures that inside text is legible
    },
    outsidetextfont: {
      color: 'black' // Ensures outside text is legible
    },
    autocolorscale: false,
  }));

  return (
<div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', height: '100%' }}>
{treemapData.map((data, index) => (
  <div key={index} style={{ flex: '1 1 0', margin: '5px' }}>
    <Plot
      data={[data]}
      layout={{
        autosize: true,
        margin: { t: 0, l: 0, r: 0, b: 0 },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: { showgrid: false, zeroline: false, visible: false }, // Remove x-axis lines
        yaxis: { showgrid: false, zeroline: false, visible: false }, // Remove y-axis lines
      }}
      useResizeHandler={true}
      style={{ width: '100%', height: '100%' }}
      config={{
        displayModeBar: false,
      }}
    />
  </div>
))}
</div>
);
};

export default TreemapChart;