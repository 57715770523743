import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const ProgressCircle = ({ value, size = 150, thickness = 5, name  }) => {
  const [progress, setProgress] = useState(0); // Start progress at 0 for animation effect
 const targetValue=value
  useEffect(() => {
    if (progress < targetValue) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= targetValue) {
            clearInterval(timer);
            return targetValue;
          }
          return prevProgress + 1; // Adjust step to control animation speed
        });
      }, 10); // Speed of animation (in milliseconds)
      return () => clearInterval(timer);
    }
  }, [progress, targetValue]);
  const getColor = () => {
    if (name === "Toxicity" || name === "Bleu") {
      if (progress >= 80) return "blue";       // High toxicity
      if (progress >= 65) return "yellow";     // Moderate toxicity
      return "green";                          // Low toxicity
    } else {
      if (progress >= 80) return "green";      // High value
      if (progress >= 65) return "orange";     // Moderate value
      return "red";                            // Low value
    }
  }
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: `${size}px`,
        height: `${size}px`,
        transform: "rotate(90deg)",
      }}
    >
      {/* Grey background circular progress */}
      <CircularProgress
        variant="determinate"
        value={100} // Full circle for the background
        size={size}
        thickness={thickness}
        sx={{
          color: "#ccc", // Grey color for the background
          position: "absolute",
        }}
      />

      {/* Animated green circular progress */}
      <CircularProgress
        variant="determinate"
        value={progress}
        size={size}
        thickness={thickness}
        // sx={{
        //   color: progress >= 100 ? "green" : "green", // Green color for progress
        // }}
        sx={{
          color: getColor(),
        }}
      />

      {/* Centered percentage text */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" component="div" color="textSecondary" sx={{ transform: "rotate(-90deg)" }}>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressCircle;
