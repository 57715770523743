
// import React, { useState, useEffect } from 'react';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip
// } from '@mui/material';

// const DataTable = ({ data }) => {
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(2);
//   const [loading, setLoading] = useState(true);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedPrompt, setSelectedPrompt] = useState('');

//   useEffect(() => {
//     // Check if data is loaded and valid
//     if (data?.length > 0) {
//       setLoading(false);
//     }
//   }, [data]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleClickPrompt = (prompt) => {
//     setSelectedPrompt(prompt);
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//     setSelectedPrompt('');
//   };

//   // Show loading spinner if data is not yet loaded
//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100%">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   // Get data keys to use as the first row if data is present
//   const dataKeys = data?.length > 0 ? Object.keys(data[0]) : [];

//   return (
//     <Paper>
//       <TableContainer sx={{ height: "155px" }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               {dataKeys.map((key, index) => (
//                 <TableCell key={index} sx={{ padding: '10px', whiteSpace: 'nowrap' }}>
//                   {key.replace(/_/g, ' ').toUpperCase()} {/* Format the key */}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
//               <TableRow key={index}>
//                 {dataKeys.map((key) => (
//                   <TableCell
//                     key={key}
//                     sx={{
//                       padding: '16px',
//                       maxWidth: key === 'prompt' ? '200px' : 'auto', // Limit width for 'Prompt' column
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                       whiteSpace: 'nowrap',
//                       cursor: key === 'prompt' ? 'pointer' : 'default' // Pointer cursor for clickable prompt
//                     }}
//                   >
//                     {key === 'prompt' ? (
//                       <Tooltip title="Click to view more details" arrow>
//                         <span onClick={() => handleClickPrompt(row[key])} style={{ display: 'inline-block', width: '100%' }}>
//                           {row[key]}
//                         </span>
//                       </Tooltip>
//                     ) : (
//                       row[key]
//                     )}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[2, 4, 6]}
//         component="div"
//         count={data?.length || 0}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
      
//       {/* Dialog for displaying full prompt */}
//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>Full Prompt</DialogTitle>
//         <DialogContent dividers>
//           <Box>{selectedPrompt}</Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Paper>
//   );
// };

// export default DataTable;



import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip
} from '@mui/material';

const DataTable = ({ data,loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  // const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [selectedRecommendation, setSelectedRecommendation] = useState(''); // New state for recommendations

  // useEffect(() => {
  //   // Check if data is loaded and valid
  //   if (data?.length > 0) {
  //     setLoading(false);
  //   }
  // }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickPrompt = (prompt) => {
    setSelectedPrompt(prompt);
    setOpenDialog(true);
  };

  const handleClickRecommendation = (recommendation) => {
    setSelectedRecommendation(recommendation);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPrompt('');
    setSelectedRecommendation('');
  };

  // Show loading spinner if data is not yet loaded
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress sx={{color:'white'}} />
      </Box>
    );
  }

  // Get data keys to use as the first row if data is present
  const dataKeys = data?.length > 0 ? Object.keys(data[0]) : [];

  return (
    <Paper>
      <TableContainer sx={{ height: "155px" }}>
        <Table>
          <TableHead>
            <TableRow>
              {dataKeys.map((key, index) => (
                <TableCell key={index} sx={{ padding: '10px', whiteSpace: 'nowrap' }}>
                  {key.replace(/_/g, ' ').toUpperCase()} {/* Format the key */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                {dataKeys.map((key) => (
                  <TableCell
                    key={key}
                    sx={{
                      padding: '16px',
                      maxWidth: (key === 'prompt' || key === 'recommendations') ? '200px' : 'auto', // Limit width for 'Prompt' and 'Recommendations' columns
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: (key === 'prompt' || key === 'recommendations') ? 'pointer' : 'default' // Pointer cursor for clickable fields
                    }}
                  >
                    {key === 'prompt' ? (
                      <Tooltip title="Click to view more details" arrow>
                        <span onClick={() => handleClickPrompt(row[key])} style={{ display: 'inline-block', width: '100%' }}>
                          {row[key]}
                        </span>
                      </Tooltip>
                    ) : key === 'recommendations' ? (
                      <Tooltip title="Click to view more details" arrow>
                        <span onClick={() => handleClickRecommendation(row[key])} style={{ display: 'inline-block', width: '100%' }}>
                          {row[key]}
                        </span>
                      </Tooltip>
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[2, 4, 6]}
        component="div"
        count={data?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
      {/* Dialog for displaying full prompt or recommendation */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPrompt ? 'Full Prompt' : 'Full Recommendation'}</DialogTitle>
        <DialogContent dividers>
          <Box>{selectedPrompt || selectedRecommendation}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DataTable;
