import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, Button, IconButton, Typography, useTheme ,Grid, Paper, List, ListItem,CircularProgress  } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({ data,mname ,color,loading,labelY}) => {
  // Prepare the labels and datasets from the data prop
  const labels = data.map(item => item.metrics_name || item.model ); // Assuming 'name' is the key for X-axis labels
  const dataset1 = data.map(item => item.cost || item.pass_rate || item.prompt); // Assuming 'passRate' is the key for Y-axis data
  const isPassRate = labelY === "Pass Rate %";
  const modelNames = data.map(item => item.model);
  const uniqueModelNames = [...new Set(modelNames)];
  const uniqueDataset = uniqueModelNames.map(model => {
    // Sum or average the values based on the model if needed
    const values = dataset1.filter((_, index) => modelNames[index] === model);
    return values.reduce((acc, val) => acc + val, 0); // Sum the values; adjust if needed
  });
  // Prepare the datasets for Chart.js
  const chartData = {
    labels: labels  ,
    datasets: [
      {
        // label: labelY,
        label:  labelY,
        data: dataset1,
        backgroundColor: color, // Main color
      },
    ],
  };

  // Chart options
  const options = {
    plugins: {
      title: {
        display: true,
        // text: "Pass Rate Bar Chart", // Title of the chart
      },
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#ffffff", // Set label color to white
          font: {
            size: 8, // Adjust size if needed
          },
          maxRotation: 45, // Allow rotation for better fitting
          minRotation: 0, // Keep labels straight
        },
        title: {
          display: true,
          text: mname,
          color: "#ffffff",
        },
        // Add padding to avoid label overlap
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
         max: Math.ceil(Math.max(...dataset1) * 1.1), // Set max slightly higher than the max value in the dataset
        // max: 100, // Set max to 1 for a 0-1 scale
        ticks: {
          color: "#ffffff", // Set y-axis label color to white
          font: {
            size: 8, // Smaller font size for better fitting
          },
          stepSize: .8
          , // Display ticks at 0.2 intervals
          callback: (value) => {
            // if ([0, 50, 100].includes(value)) {
            //   return value; // Only show 0, 50, and 100
            // }
            if (value % 4 === 0) { // Show labels at 0, 0.2, 0.4, etc.
              return value.toFixed(1); // Display one decimal place
            }
            return null; // Do not display other values
          },
        },
        title: {
          display: true,
          text: labelY,
          color: "#ffffff",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Ensure responsiveness
  };

  return (
    <div style={{ width: "100%", height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
    {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <CircularProgress color="inherit" />
                  </Box>
    ) : (
      <Bar data={chartData} options={options} />
    )}
  </div>
  );
};

export default BarGraph;
