


import React from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import { Box, IconButton, Typography, useTheme ,Grid, List, ListItem,CircularProgress ,Popover, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { useState,useEffect } from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the necessary components
ChartJS.register(
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const RadarChart = ({ data }) => {
    const [loading, setLoading] = useState(true); 
    useEffect(() => {
        // Set loading to true if data is empty, otherwise set it to false
        if (!data || !data.labels || !data.datasets) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [data]); // Depend on the data prop to update loading state

    // Check if data is defined
    if (!data || !data.labels || !data.datasets) {
        console.error("Invalid data:", data);
        return null; // or return a loading/error state
    }

    

    const labels = data.labels;
    const datasets = data.datasets.map((dataset) => ({
        label: dataset.label,
        data: dataset.data,
        backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
        borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
        borderWidth: 1,
    }));

    const Newdata = {
        labels: labels,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, 
        scales: {
            r: {
                ticks: {},
                grid: {
                    color: "white"
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: 'white',
                },
            },
        },
    };

    return (
        <div style={{ width: '84%', height: '300px' , margin:" auto"}}>
         {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" >
                    <CircularProgress color="inherit" />
                  </Box>
    ) :
            <Radar data={Newdata} options={options} />
         }
        </div>
    );
};

// Prop validation
RadarChart.propTypes = {
    data: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        datasets: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                data: PropTypes.arrayOf(PropTypes.number).isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default RadarChart;
