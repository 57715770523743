import { Box, IconButton, Typography, useTheme ,Grid,Modal, Paper , List, ListItem,CircularProgress ,Popover, MenuItem, Checkbox, ListItemText, Button,Tooltip } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { tokens } from "../../theme";
import BarGraph from "../../components/BarChart";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ProgressCircle from "../../components/ProgressCircle";
import BarChartComponent from "../../components/BarChartComponent";
import LineChart from "../../components/LineChart";
import InfoIcon from '@mui/icons-material/Info';

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null); // State to handle popover anchor
  const [apidata, setApidata] = useState([]);
  const [error, setError] = useState(null);
  const [loadingMetrics, setLoadingMetrics] = useState(true); // Loading state for metrics
  const [loadingTopMetrics, setLoadingTopMetrics] = useState(true); // Loading state for top metrics
  const [costByModal,setCostByModal]= useState([])
const [ passRate, setPassRate]= useState([])
const [loadingCostByModal, setLoadingCostByModal] = useState(true); // Loading state for metrics
  const [loadingPassRate, setLoadingPassRate] = useState(true); // Loading state for top metrics
  const [loadingLineGrapgh, setLoadingLineGrapgh] = useState(true); // Loading state for top metrics
  const [selectedProjects, setSelectedProjects] = useState([]);
const [lineGrapghdata,setLineGrapghdata] =useState([])
  const [selectedModels, setSelectedModels] = useState({});
  const [filter,setFilter]= useState([])
  const [appliedFilters, setAppliedFilters] = useState({ projects: [], models: [] });
  const [openImodal, setOpenImodal] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(null);
 
  // Handler to open and close the modal
  const handleOpen = (metricName) => {
    console.log(metricName)
    setSelectedMetric(metricName); // Set the metric name
    setOpenImodal(true); // Open the modal
  };

  // Handle closing the modal
  const handleClose = () => {
    setOpenImodal(false); // Close the modal
    setSelectedMetric(null); // Clear the selected metric
  };
  const [openModelMenu, setOpenModelMenu] = useState(null); // Track which model menu is open
  const metricDetails = {
    "Content Coverage": `The content coverage measures how much content or context is being compared in the actual output with respect to the retrieved context provided to LLM. 
    Input arguments: actual output, retrieval context`,

    "Contextual Relevancy": `The contextual relevancy metric assesses the overall relevance of the information displayed in your retrieval context for a particular input. 
    Input arguments: input, actual output, retrieval context`,

    "Bleu": `The BLEU (Bilingual Evaluation Understudy) score measures the similarity between a machine-generated text and a reference human translation. It evaluates how closely the machine output matches the human reference, often used in translation tasks. 
    Input arguments: input, actual output`,

    "Answer Relevancy": `The answer relevancy metric assesses the quality of your RAG pipeline's generator by determining how relevant the actual output of your LLM application is to the provided input. 
    Input arguments: input, actual_output`,

    "Toxicity": `The toxicity metric is another reference-less metric that evaluates toxicness in your LLM outputs. 
    Input arguments: input, actual_output`,

    "Diversity": `Evaluates the variety of responses generated. It promotes creativity and variation in output. 
    Input arguments: actual_output`,

    "Faithfulness": `The faithfulness metric assesses the quality of your RAG pipeline's generator by determining whether the actual output accurately matches the contents of your retrieval context. 
    Input arguments: input, actual_output, retrieval context`,

    "Correctness (GEval)": `Assessing correctness involves comparing an LLM's actual output with the retrieved context and penalizing based on user input. 
    Input arguments: input, actual output, retrieval context`,

    "Latency": `It is a measure of how long it takes for an LLM to generate a response to a user's prompt.`,

    "Contextual Precision": `The contextual precision metric measures your RAG pipeline's retriever by evaluating whether nodes in your retrieval context that are relevant to the given input are ranked higher than irrelevant ones. 
    Input arguments: input, actual_output, expected_output, retrieval_context`,

    "Contextual Recall": `The contextual recall metric measures the quality of your RAG pipeline's retriever by evaluating the extent to which the retrieval context aligns with the expected output. 
    Input arguments: input, actual_output, expected_output, retrieval_context`
  };
  const fetchMetricsData = async (projects, models) => {

    try {
      setLoadingMetrics(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   
 const modelParams = models.length > 0 
   ? models
       .flatMap((modelList) => modelList)
       .map((model) => `model_filter=${encodeURIComponent(model)}`)
       .join('&')
   : '';

 // Construct the base URL
 let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/get_metrics_average';
 
 // Only add query parameters if they exist
 if (projectParams || modelParams) {
   url += `?${[projectParams, modelParams].filter(Boolean).join('&')}`;
 }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      setApidata((prev) => ({ ...prev, metrics: data }));
      
    } catch (err) {
      setError(err.message);
    }
    finally {
      setLoadingMetrics(false); // Stop loading once data is fetched
    }

  };



  const fetchLineGrapgh = async (projects, models) => {

    try {
      setLoadingLineGrapgh(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   
 const modelParams = models.length > 0 
   ? models
       .flatMap((modelList) => modelList)
       .map((model) => `model_filter=${encodeURIComponent(model)}`)
       .join('&')
   : '';

 // Construct the base URL
 let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/line_graph';
 
 // Only add query parameters if they exist
 if (projectParams || modelParams) {
   url += `?${[projectParams, modelParams].filter(Boolean).join('&')}`;
 }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      setLineGrapghdata(data)
      // setApidata((prev) => ({ ...prev, metrics: data }));
      
    } catch (err) {
      setError(err.message);
    }
    finally {
      setLoadingLineGrapgh(false); // Stop loading once data is fetched
    }

  };
  const fetchTopMetricData = async (projects, models) => {
    try {
      setLoadingTopMetrics(true);
  
      // Construct query parameters conditionally based on selected filters
      const projectParams = projects.length > 0 
        ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
        : '';
        
      const modelParams = models.length > 0 
        ? models
            .flatMap((modelList) => modelList)
            .map((model) => `model_filter=${encodeURIComponent(model)}`)
            .join('&')
        : '';
  
      // Build the URL with conditional parameters
      let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/fetch_top_metrics';
      
      // Append query parameters only if they exist
      if (projectParams || modelParams) {
        url += `?${[projectParams, modelParams].filter(Boolean).join('&')}`;
      }
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          access_token: 'api_key_1' // Replace with actual token
        }
      });
  
      if (!response.ok) {
        throw new Error(`Top Metric Error: ${response.status}`);
      }
  
      const data = await response.json();
      setApidata((prev) => ({ ...prev, cost: data }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingTopMetrics(false); // Stop loading once data is fetched
    }
  };
  
  const fetchCostByModal = async (projects, models) => {
    try {
      setLoadingCostByModal(true);
    
      // Construct query parameters based on selected filters
      const projectParams = projects.length > 0 
        ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
        : '';
        
      const modelParams = models.length > 0 
        ? models
            .flatMap((modelList) => modelList)
            .map((model) => `model_filter=${encodeURIComponent(model)}`)
            .join('&')
        : '';
  
      // Build the URL with optional query parameters
      let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/cost_by_model';
      
      // Append query parameters if any exist
      if (projectParams || modelParams) {
        url += `?${[projectParams, modelParams].filter(Boolean).join('&')}`;
      }
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          access_token: 'api_key_1' // Replace with actual token
        }
      });
  
      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }
  
      const data = await response.json();
      setCostByModal(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingCostByModal(false);
    }
  };

  const fetchPassRate = async (projects, models) => {
    try {
      setLoadingPassRate(true);
  
      // Construct query parameters based on selected filters
      const projectParams = projects.length > 0 
        ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
        : '';
        
      const modelParams = models.length > 0 
        ? models
            .flatMap((modelList) => modelList)
            .map((model) => `model_filter=${encodeURIComponent(model)}`)
            .join('&')
        : '';
  
      // Build the URL with optional query parameters
      let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/pass_rate';
      
      // Append query parameters if any exist
      if (projectParams || modelParams) {
        url += `?${[projectParams, modelParams].filter(Boolean).join('&')}`;
      }
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          access_token: 'api_key_1' // Replace with actual token
        }
      });
  
      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }
  
      const data = await response.json();
      setPassRate(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingPassRate(false);
    }
  };
  

  useEffect(() => {
  
    
    const fetchFilter = async () => {
      try {
        const response = await fetch(
          'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/distinct',
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              access_token: 'api_key_1' // Replace with actual token
            }
          }
        );
  
        if (!response.ok) {
          throw new Error(`Metrics Error: ${response.status}`);
        }
  
        const data = await response.json();
        setFilter(data);
      } catch (err) {
        setError(err.message);
      }
      // finally {
      //   setLoadingMetrics(false); // Stop loading once data is fetched
      // }

    };
    
   
    fetchFilter()
    fetchLineGrapgh(appliedFilters.projects, appliedFilters.models)
    fetchCostByModal(appliedFilters.projects, appliedFilters.models)
    fetchMetricsData(appliedFilters.projects, appliedFilters.models);
    fetchTopMetricData(appliedFilters.projects, appliedFilters.models);
    fetchPassRate(appliedFilters.projects, appliedFilters.models);
  }, [appliedFilters]);
  
  
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the popover
  };

  const handleFilterClose = () => {
    setAnchorEl(null); // Close the popover by setting anchor to null
  };

  const handleProjectSelect = (project) => {
    setSelectedProjects((prev) => {
      const newSelected = prev.includes(project)
        ? prev.filter((p) => p !== project) // Unselecting the project
        : [...prev, project]; // Selecting the project
      
      // Clear models if the project is unselected
      if (!prev.includes(project)) {
        setSelectedModels((prevModels) => ({
          ...prevModels,
          [project]: [], // Resetting the models for this project
        }));
      } else {
        // Remove models for the unselected project
        const { [project]: removedProject, ...rest } = selectedModels;
        setSelectedModels(rest);
      }

      // Toggle the model menu for the selected project
      setOpenModelMenu(newSelected.includes(project) ? project : null);

      return newSelected;
    });
  };

  const handleModelSelect = (project, model) => {
    setSelectedModels((prev) => {
      const projectModels = prev[project] || [];
      return {
        ...prev,
        [project]: projectModels.includes(model)
          ? projectModels.filter((m) => m !== model)
          : [...projectModels, model],
      };
    });
  };


// console.log(selectedModels)


  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;
  
  
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleApply = () => {
    const projects = selectedProjects;
    const models = selectedProjects.map((project) => selectedModels[project] || []);
    fetchLineGrapgh(projects, models);
    fetchMetricsData(projects, models); // Pass selected projects and models to the API call
    fetchTopMetricData(projects, models);
    fetchCostByModal(projects, models)
    fetchPassRate(projects, models)
    // Store the applied filters in state
  setAppliedFilters({ projects, models });

    handleFilterClose(); // Optionally close the popover after applying
  };
   
  return (
    <Box m="20px" sx={{ overflow: "auto", height: "calc(100vh - 100px)", backgroundColor:
    "#121212" }}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
       
        sx={{
              
              borderBottom: "1px solid white",
              // padding: "2px 6px",
              // borderRadius: "4px",
              // boxShadow: "0 0 5px white",
              
              paddingRight: "10px" 
            }}
      >
        

        <Box display="flex" alignItems="center">
          {/* Filter Button with Text */}
          <Box
            display="flex"
            alignItems="center"
          
          >
            <IconButton sx={{ color: colors.grey[100], mr: "5px" }} onClick={handleFilterClick}>
              <FilterAltIcon /> {/* Import this icon at the top */}
            </IconButton>
            {/* <Button
              sx={{
                color: colors.grey[100],
                cursor: "pointer",
                border:"1px solid white"
              }} 
              onClick={handleApply}
            >
               Apply Filter
            </Button> */}
          </Box>
  
        </Box>
       
      
    

        <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleFilterClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2}>
        <Typography variant="subtitle1">Filter Options</Typography>
        {filter.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleProjectSelect(item.project)}
          >
            <Checkbox checked={selectedProjects.includes(item.project)} />
            <ListItemText primary={item.project} />
          </MenuItem>
        ))}
      </Box>

      {/* Models Submenu for each selected project */}
      {selectedProjects.map((project, index) => (
        <Popover
          key={index}
          open={openModelMenu === project}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => setOpenModelMenu(null)} // Close the model menu when clicked outside
        >
          <Box p={2}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Models for {project}
            </Typography>
            {filter.find((item) => item.project === project).models.map((model, modelIndex) => (
              <MenuItem
                key={modelIndex}
                onClick={() => handleModelSelect(project, model)}
              >
                <Checkbox
                  checked={selectedModels[project]?.includes(model) || false}
                />
                <ListItemText primary={model} />
              </MenuItem>
            ))}

            
          </Box>
        </Popover>
      ))}
      <Box sx={{ mt: 2, pb: 1,pt: 1, borderTop: '1px solid #ddd', display: 'flex', justifyContent: 'center' }}>
     
      <Button
              sx={{
                color: colors.grey[100],
                cursor: "pointer",
                border:"1px solid white"
              }} 
              onClick={handleApply}
            >
               Apply Filter
            </Button>
    </Box>
    </Popover>
      </Box>

    
      {/* GRID & CHARTS */}
     



      <div style={{ padding: '00px', }}>
        <Grid container spacing={3} sx={{ margin: "0 auto", width: '98%' }}  >
          {/* Left Column: Overview Section */}
          <Grid item xs={12} md={12} >
            <Grid container spacing={3}>
              <Grid item xs={12} >
                <Typography variant="h5" sx={{ color: colors.grey[100], margin: "20px 0", marginLeft:"-20px" }}>
                  Overview
                </Typography>
              </Grid>

              {/* Overview Stats */}
              <Grid container spacing={3} > 
              
                { loadingTopMetrics ? (
                  <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <CircularProgress color="inherit" />
                  </Box>
                ) :apidata?.cost?.[0]?.metrics?.map((metric, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box elevation={3} backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="80px" borderRadius="10px" padding="0 10px">
                  <Typography variant="span" fontWeight="bold" sx={{ color: colors.grey[100], fontSize: "34px" }}>
                  {/* {Number.isInteger(metric.value) ? metric.value.toString() : metric.value.toFixed(2)} */}
                  {metric.metrics_name === "Total Cost" ? 
          `$ ${Number.isInteger(metric.value) ? metric.value : metric.value.toFixed(2)}` :
         metric.metrics_name === "Average Latency" ?
          ` ${Number.isInteger(metric.value) ? metric.value : metric.value.toFixed(2)} sec` :
          metric.metrics_name === "Total Token" && metric.value >= 1000000 ? 
          `${(metric.value / 1000000).toFixed(1)}M` :
         metric.metrics_name === "Total Token" && metric.value >= 1000 ? 
          `${(metric.value / 1000).toFixed(1)}K` :
          metric.metrics_name === "Total User Query" && metric.value >= 1000000 ?
    `${(metric.value / 1000000).toFixed(1)}M` :
  metric.metrics_name === "Total User Query" && metric.value >= 1000 ?
    `${(metric.value / 1000).toFixed(1)}K` :
  Number.isInteger(metric.value) ? metric.value : metric.value.toFixed(2)
        }
                  </Typography>
                      <Typography variant="h6" align="center" 
          sx={{ marginBottom: "10px", color: colors.grey[100] }}>{metric.metrics_name}</Typography>
                      
                  </Box>
                  </Grid>
      ))}

             
              </Grid>

              {/* Additional Metrics Section */}
              <Typography variant="h5" style={{ margin: '20px 0' }}>Metrics</Typography>
              <Grid container spacing={3}>

                {
                   loadingMetrics?(
                  <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <CircularProgress color="inherit" />
                  </Box>
                ) :  apidata?.metrics?.map((metric, index) => (
                  <Grid item xs={12} sm={6} md={2.4} key={index}>
                  <Box elevation={3} backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="150px" borderRadius="10px" padding="0 10px" position="relative">
                  <Box position="absolute" top="10px" right="10px">
      <Tooltip title="Additional Information">
        <IconButton color="inherit" size="small" onClick={() => handleOpen(metric.metrics_name)}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Box>
                      <Typography variant="h6" align="center" 
          sx={{ marginBottom: "10px", color: colors.grey[100] }}>{metric.metrics_name}</Typography>
                      
                      <Box display="flex" alignItems="center" justifyContent="center">
          <ProgressCircle value={(metric.average_score * 100).toFixed(2)} size={100}  name={metric.metrics_name}/>
        </Box>
                    </Box>


                    
                    <Modal 
  open={openImodal} 
  onClose={handleClose} 
  aria-labelledby="modal-title" 
  aria-describedby="modal-description"
  BackdropProps={{
    style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' }  // Makes the backdrop transparent
  }}
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}
>
  <Paper 
    style={{
      width: '300px', 
      padding: '20px', 
      outline: 'none',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)'
    }}
  >
    
    {selectedMetric && (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                {selectedMetric} Details
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                {metricDetails[selectedMetric] || 'Detailed information about this metric goes here.'}
              </Typography>
            </>
          )}
  </Paper>
</Modal>

                  </Grid>
                ))}
              </Grid>

              <Typography variant="h5" style={{ margin:"20px 0" }}>Cost By Model</Typography>
               <Grid container spacing={3} sx={{ margin: 0, width: '100%' }}  >
                
                  <Grid item xs={12} sm={12} md={12} backgroundColor={colors.primary[400]} borderRadius="10px" >
                  
                    <Box height="250px" mt="-20px"  >
                    {/* <LineChart data={lineGrapghdata}  /> */}

                    {loadingLineGrapgh ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="250px">
                    <CircularProgress color="inherit" />
                  </Box>: <LineChart data={lineGrapghdata}  />}
            
          </Box>
          
                  </Grid>
              
              </Grid>

              <Typography variant="h5" style={{ margin:"20px 0" }}>Cost By Model</Typography>
               <Grid container spacing={3} sx={{ margin: 0, width: '100%' }}  >
                
                  <Grid item xs={12} sm={12} md={12} backgroundColor={colors.primary[400]} borderRadius="10px" >
                  
                    <Box height="250px" mt="-20px"  >
            <BarGraph data={costByModal} mname="Model" color="#B277D7" loading={loadingCostByModal} labelY="Cost ($)"/>
          </Box>
          
                  </Grid>
              
              </Grid>

               <Typography variant="h5" style={{ margin:"20px 0" }}>Pass Rate % - By Metrics Name</Typography>
               <Grid container spacing={3} sx={{ margin: 0, width: '100%' }}  >
                
                  <Grid item xs={12} sm={12} md={12} backgroundColor={colors.primary[400]} borderRadius="10px" >
                  
                    <Box height="250px" mt="-20px"  >
            
            <BarGraph data={passRate} mname="Metrics" color="#EF4819" loading={loadingPassRate} labelY="Pass Rate %"/>
          </Box>
          
                  </Grid>
              
              </Grid>
            </Grid>
          </Grid>

          {/* Right Column: Recent Prompts */}
          {/* <Grid item xs={12} md={4}>
            <Typography variant="h5" style={{ margin: '20px 0' }}>Recent Prompts</Typography>
           

            <Box elevation={3} backgroundColor={colors.primary[400]} display="flex" alignItems="start" justifyContent="start" flexDirection="column" borderRadius="10px" padding="0 10px" height="100vh" overflow="auto">
              <List>
                {apidata?.cost?.[1]?.["Recent Prompt"]?.map((item, index) => (
                  <ListItem key={index}>{item}</ListItem>
                ))}
              </List>
            </Box>
          </Grid> */}
        </Grid>
      </div>
    </Box>
  );
};

export default Dashboard;