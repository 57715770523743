

import { Box, Button, Typography, useTheme ,Menu, MenuItem, Checkbox, FormControlLabel ,CircularProgress,IconButton} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState,useEffect } from "react";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import BarGraph from "../../components/BarChart";
import RadarChart from "../../components/RadarChart";
import TreemapChart from "../../components/TreemapChart";
import DataTable from "../../components/DataTable";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const Team = () => {
  const [activeTab, setActiveTab] = useState("Analysis");
  const [promtgrapghData,setPromtgrapghData] =useState([])
  const [securityData,setSecurityData] =useState([])
  const [loadingMetrics,setLoadingMetrics]= useState(false)
  const [fairnessData,setFairnessData] =useState([])
  const [loadingFairness,setLoadingFairness]= useState(false)
  const [loadingPromptTabel,setLoadingPromptTabel]= useState(false)
  const [recommendationData,setRecommendationData]= useState([])
  const [loadingsecurity,setLoadingsecurity]= useState(false)
  const [filter,setFilter]= useState([])
  const [appliedFilters, setAppliedFilters] = useState({ projects: [] });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [promptTable    ,setPromptTable] =useState([])
  const [filterAnchorEl, setFilterAnchorEl] = useState(null); // For dropdown anchor
  const [filterOptions, setFilterOptions] = useState([]); // Options for filter dropdown
  const [selectedFilters, setSelectedFilters] = useState([]); // Track selected filters
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState('Prompt'); // Default selected value
const[loadingRecommendation,setLoadingRecommendation]=useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the dropdown
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the dropdown
  };

  const handleSelect = (type) => {
    setSelectedType(type); // Update the selected type
    handleClose(); // Close the dropdown
  };
  console.log(selectedType)
  const fetchPromptTabel = async (projects ) => {

    try {
      setLoadingPromptTabel(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   

 // Construct the base URL
 let url = ' http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/prompt_table';
 
 // Only add query parameters if they exist
 if (projectParams ) {
   url += `?${[projectParams].filter(Boolean).join('&')}`;
 }

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          },
         
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      setPromptTable(data);
      
    } catch (err) {
      console.log(err.message);
    }
    finally {
      setLoadingPromptTabel(false); // Stop loading once data is fetched
    }

  };
  
  const fetchMetricsData = async (projects, ) => {

    try {
      setLoadingMetrics(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   

 // Construct the base URL
 let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/prompt_bar_graph';
 
 // Only add query parameters if they exist
 if (projectParams ) {
   url += `?${[projectParams].filter(Boolean).join('&')}`;
 }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      setPromtgrapghData(data);
      
    } catch (err) {
      console.log(err.message);
    }
    finally {
      setLoadingMetrics(false); // Stop loading once data is fetched
    }

  };
  const fetchSecurity = async (projects,selectedType ) => {

    try {
      setLoadingsecurity(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   
   const scannerParams = selectedType ? `scanner_filter=${encodeURIComponent(selectedType)}` : '';
   
 // Construct the base URL
 let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/security';
 
 // Only add query parameters if they exist
 if (projectParams ) {
   url += `?${[projectParams,scannerParams].filter(Boolean).join('&')}`;
 }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      setSecurityData(data);
      
    } catch (err) {
      console.log(err.message);
    }
    finally {
      setLoadingsecurity(false); // Stop loading once data is fetched
    }

  };

  
 
  const fetchRecommendation = async (projects,selectedType ) => {

    try {
      setLoadingRecommendation(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   
   const scannerParams = selectedType ? `scanner_filter=${encodeURIComponent(selectedType)}` : '';
   
 // Construct the base URL
 let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/recommendation';
 
 // Only add query parameters if they exist
 if (projectParams ) {
   url += `?${[projectParams,scannerParams].filter(Boolean).join('&')}`;
 }
 
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
           
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      setRecommendationData(data);
      
    } catch (err) {
      console.log(err.message);
    }
    finally {
      setLoadingRecommendation(false); // Stop loading once data is fetched
    }

  };
  const fetchFairnessData = async (projects,selectedType ) => {

    try {
      setLoadingFairness(true);
   // Initialize query parameters
   const projectParams = projects.length > 0 
   ? projects.map((project) => `project_filter=${encodeURIComponent(project)}`).join('&')
   : '';
   
   const scannerParams = selectedType ? `scanner_filter=${encodeURIComponent(selectedType)}` : '';
   
   setLoadingFairness(true);
 // Construct the base URL
 let url = 'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/fairness';
 
 // Only add query parameters if they exist
 if (projectParams ) {
   url += `?${[projectParams,scannerParams].filter(Boolean).join('&')}`;
 }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
        });

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      setFairnessData(data);
      
    } catch (err) {
      console.log(err.message);
    }
    finally {
      setLoadingFairness(false); // Stop loading once data is fetched
    }

  };

  const fetchFilter = async () => {
    try {
      const response = await fetch(
        'http://llmeval-backend-1935191857.ap-south-1.elb.amazonaws.com/api/v1/distinct',
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            access_token: 'api_key_1' // Replace with actual token
          }
        }
      );

      if (!response.ok) {
        throw new Error(`Metrics Error: ${response.status}`);
      }

      const data = await response.json();
      setFilterOptions(data);
    } catch (err) {
      console.log(err.message);
    }
    // finally {
    //   setLoadingMetrics(false); // Stop loading once data is fetched
    // }

  };
  // console.log(filterOptions)

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  // Handle filter selection
  const handleFilterSelect = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter((item) => item !== filter);
      }
      return [...prevFilters, filter];
    });
  };

  // Apply filters
  const handleApplyFilters = () => {
    // Create a distinct array of selected projects
    const distinctProjects = [...new Set(selectedFilters)]; // assuming selectedFilters holds project names or identifiers
    setSelectedProjects(distinctProjects); // update selectedProjects state
    setAppliedFilters({ projects: distinctProjects }); // Update applied filters
    handleFilterClose(); // close dropdown
  };
  useEffect(() => {
    fetchFilter()
    fetchMetricsData(appliedFilters.projects)
    fetchFairnessData(appliedFilters.projects,selectedType)
    fetchPromptTabel(appliedFilters.projects)
    fetchRecommendation(appliedFilters.projects,selectedType)
    fetchSecurity(appliedFilters.projects,selectedType)
  }, [appliedFilters,selectedType]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


 

  return (
    <Box m="20px">
      <Box sx={{ borderBottom: "2px solid white", marginBottom: "10px" }}>
      <Box sx={{display:"flex", justifyContent:"space-between"}}>
      <Box>
        <Button
          sx={{
            color: "white",
            borderBottom: activeTab === "Analysis" ? `4px solid ${colors.greenAccent[500]}` : "none",  borderRadius:"0px"
          }}
          onClick={() => setActiveTab("Analysis")}
        >
          Analysis
        </Button>
        <Button
          sx={{
            color: "white",
            borderBottom: activeTab === "Recommendation" ? `4px solid ${colors.greenAccent[500]}` : "none",borderRadius:"0px"
          }}
          onClick={() => setActiveTab("Recommendation")}
        >
          Recommendation
        </Button>
        </Box>
        
        <Box display="flex" alignItems="center">
         
          <Box
            display="flex"
            alignItems="center"
          
          >
            <IconButton sx={{ color: colors.grey[100], mr: "5px" }} 
             onClick={handleFilterClick}
            >
              <FilterAltIcon /> 
            </IconButton>
            <Button
              sx={{
                color: colors.grey[100],
                cursor: "pointer",
                border:"1px solid white",
                width:"100px"
              }} 
              onClick={handleClick}
            >
 {selectedType}           </Button>
 <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect('Query')}>
          <Typography>Query</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleSelect('LLM Output')}>
          <Typography>LLM Output</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleSelect('Prompt')}>
          <Typography>Prompt</Typography>
        </MenuItem>
      </Menu>
          </Box>
  
        </Box>
        </Box>
        <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={handleFilterClose}>
  {filterOptions.map((option, index) => (
    <MenuItem key={index}>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedFilters.includes(option.project)} // or option.providers, etc.
            onChange={() => handleFilterSelect(option.project)} // adjust key if necessary
          />
        }
        label={option.project} // Display the appropriate key here
      />
    </MenuItem>
  ))}
  {/* <MenuItem onClick={handleApplyFilters} sx={{ justifyContent: "center" }}> */}
  <Box sx={{ mt: 2, pb: 1,pt: 1, borderTop: '1px solid #ddd', display: 'flex', justifyContent: 'center' }}>
     
     <Button
             sx={{
               color: colors.grey[100],
               cursor: "pointer",
               border:"1px solid white"
             }} 
            
           >
              Apply Filter
           </Button>
   </Box>
  {/* </MenuItem> */}
</Menu>


      </Box>

      {activeTab === "Analysis" && (
        <Box
          m="0px 0 0 0"
           height="78vh"
           overflow="auto"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
            sx={{
              // maxWidth: "1200px",
              margin: "20px auto",
              marginTop: "0",
              paddingRight: "10px",
            }}
          >
          
            <Box gridColumn="span 4" gridRow="span 2" >
            <Typography variant="h6" fontWeight="600" sx={{ padding: "0px 0px 0 0" }}>
            Result of prompt used
              </Typography>
              <Box height="300px" mt="-20px" backgroundColor={colors.primary[400]} display="flex" justifyContent="center" alignItems="center" borderRadius="10px" padding=" 0 25px" margin="20px 0">
                <BarGraph data={promtgrapghData} mname="Models" color="#B277D7" labelY="Promt Count"  loading={loadingMetrics}/>

             
                
              </Box>
            </Box>
            <Box gridColumn="span 4" gridRow="span 2" >
            <Typography variant="h6" fontWeight="600" sx={{ padding: "0px 0px 0 0" }}>
            Fairness
              </Typography>
              <Box height="300px" mt="-20px" backgroundColor={colors.primary[400]} display="flex" width="100%" borderRadius="10px" margin="20px 0">
                {/* <BarGraph data={data2} mname="GPT-3.5-Turbo" /> */}
                {loadingFairness ? (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" >
                    <CircularProgress color="inherit" />
                  </Box>
    ) :<RadarChart data={fairnessData} loading={loadingFairness}/>}
              </Box>
            </Box>
            <Box gridColumn="span 4" gridRow="span 2" >
            <Typography variant="h6" fontWeight="600" sx={{ padding: "0px 0px 0 0" }}>
              Security
              </Typography>
              <Box height="300px" mt="-20px" backgroundColor={colors.primary[400]} display="flex" justifyContent="center"  borderRadius="10px" margin="20px 0">
             {loadingsecurity ? ( <Box display="flex" justifyContent="center" alignItems="center" width="100%" >
                    <CircularProgress color="inherit" />
                  </Box>
    ):<TreemapChart data={securityData}/> } 
                
                
              </Box>
            </Box>
            <Box gridColumn="span 12" gridRow="span 2" marginTop="40px" > 
            
            {loadingPromptTabel ? ( <Box display="flex" justifyContent="center" alignItems="center" width="100%" >
                    <CircularProgress color="inherit" />
                  </Box>
    ): <DataTable data= {promptTable}/> } 
           </Box>
          </Box>
        </Box>
      )}
        

      {activeTab === "Recommendation" && (
        <Box>
        <DataTable data= {recommendationData} loading={loadingRecommendation}/>
        </Box>
      )}
      
    </Box>
  );
};

export default Team;
